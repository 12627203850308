// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { hideVisually } from 'polished';

// styles
import {
  magnifyStyle,
  unbreakableStringStyle,
  unstyledLinkStyle,
} from '@styles/global';

// images
import IconArrow from '@images/IconArrow';

// components
import Layout from '@components/Layout';
import Button from '@components/Button';

// views
import HeroSection from '@views/BusinessPageView/HeroSection';
import IntroSection from '@views/BusinessPageView/IntroSection';
import ProductsSection from '@views/BusinessPageView/ProductsSection';
import ProjectSection from '@views/BusinessPageView/ProjectSection';
import SEO from '@src/components/SEO';

const EntreprisesPage = ({ data }) => {
  const {
    products: { nodes: products },
    categories: { nodes: categories },
  } = data;

  const langLinks = [{ langKey: 'en', href: '/en/businesses' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        langLinks={langLinks}
        title='Entreprises'
        description='Faites confiance à Permafil pour la fabrication et l’installation de produits de rangement de qualité pour vos projets résidentiels et commerciaux.'
      />

      <HeroSection>
        <h1>
          Entreprises<span css={hideVisually()}> - </span>
          <small>
            Produits et services
            <br />
            pour les professionnels
          </small>
        </h1>
      </HeroSection>

      <IntroSection>
        <h2 css={hideVisually}>Pourquoi choisir Permafil</h2>

        <p css={magnifyStyle}>
          Choisir notre équipe, c’est se prévaloir d’un service professionnel à
          chacune des étapes de votre projet, de l’idéation à la réalisation.
        </p>

        <p>
          Notre priorité? Rien de moins que votre succès. Ici, nos experts
          dévoués s’assurent que les produits sélectionnés de même que leur
          installation satisfont vos attentes en termes de qualité, dans les
          délais qui vous conviennent.
        </p>

        <p>Notre service clé en main vous garantit la paix d’esprit.</p>
      </IntroSection>

      <ProductsSection products={[...categories, ...products]} />

      <ProjectSection>
        <h2>Vous êtes responsable d’un projet?</h2>

        <p className='subTitle'>
          Contactez-nous{' '}
          <a
            href='tel:+18004631434'
            css={[unbreakableStringStyle, unstyledLinkStyle]}
          >
            1&nbsp;800&nbsp;463-1434
          </a>
        </p>

        <Button
          outlined
          renderIcon={<IconArrow />}
          to='/nous-contacter'
          tag='link'
        >
          Contactez-nous
        </Button>

        <p>
          Un représentant de votre région se fera un plaisir de vous guider dans
          le choix des produits et services offerts par Permafil qui promettent
          de mieux répondre à vos besoins. Une fois la commande effectuée, votre
          projet sera pris en charge par l’un de nos experts qualifiés, qui
          s’assurera de votre entière satisfaction jusqu’à l’installation
          finale.
        </p>

        <p>Au plaisir de collaborer!</p>
      </ProjectSection>
    </Layout>
  );
};

EntreprisesPage.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default EntreprisesPage;

export const query = graphql`
  query entreprisesPageQuery {
    products: allSanityProduct(
      filter: {
        targetAudience: { eq: "company" }
        localization: { locale: { eq: "fr" } }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        title
        categories {
          id
        }
        localization {
          locale
        }
      }
    }

    categories: allSanityProductCategory(
      filter: {
        localization: { locale: { eq: "fr" } }
        targetAudience: { eq: "company" }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        title
        targetAudience
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        localization {
          locale
        }
      }
    }
  }
`;
